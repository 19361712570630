import DesireesAngel from '../images/artwork/collagePrints/DesireesAngel.jpg'
import Eight from '../images/artwork/collagePrints/Eight.jpg'
import HorseRace from '../images/artwork/collagePrints/HorseRace.jpg'
import Mutant from '../images/artwork/collagePrints/Mutant.jpg'
import PostureWork from '../images/artwork/collagePrints/PostureWork.jpg'
import Slake from '../images/artwork/collagePrints/Slake.jpg'
import Wing from '../images/artwork/collagePrints/Wing.jpg'
import YellowHeap from '../images/artwork/collagePrints/YellowHeap.jpg'


export const photos = [

      {
        src: Slake,
        width: 2,
        height: 1.5,
        title: "Slake, 2017"
      },
      {
        src: PostureWork,
        width: 3,
        height: 4.5,
        title: "I'm Working on my Posture, 2017"
      },
      {
        src: Wing,
        width: 4,
        height: 3.5,
        title: "Wing, 2017"
      },
      {
        src: YellowHeap,
        width: 3,
        height: 4,
        title: "Yellow Heap, 2018"
      },
      {
        src: Mutant,
        width: 8,
        height: 3,
        title: "Mutant, 2017"
      },
      {
        src: Eight,
        width: 3,
        height: 5,
        title: "Eight, 2019"
      },     
      {
        src: HorseRace,
        width: 3.5,
        height: 5,
        title: "Horse Race, 2018"
      },
      {
        src: DesireesAngel,
        width: 3.5,
        height: 4,
        title: "Desiree's Guardian Angel, 2019"
      },
  ];